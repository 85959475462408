const chalk_minimal = {
    primer: 0.2,
    mesh: 0,
    mid: 1.5,
    base: 0,
    subbase: 0,
    siloxan: 0,
    color: 0.25,
    lack: 0.17,
    polyurtan_matte: 0.125,
    polyurtan_shine: 0.125,
    hardner: 0.350
};
const chalk_island = {
    primer: 0.2,
    mesh: 0,
    mid: 1.7,
    base: 0,
    subbase: 0,
    siloxan: 0,
    color: 0.25,
    lack: 0.18,
    polyurtan_matte: 0.125,
    polyurtan_shine: 0.125,
    hardner: 0.350
};
const chalk_spina = {
    primer: 0.2,
    mesh: 0,
    mid: 1.4,
    base: 0,
    subbase: 0,
    siloxan: 0,
    color: 0.25,
    lack: 0.17,
    polyurtan_matte: 0.125,
    polyurtan_shine: 0.125,
    hardner: 0.350
};
const chalk_ronic = {
    primer: 0.2,
    mesh: 0,
    mid: 1.6,
    base: 0,
    subbase: 0,
    siloxan: 0,
    color: 0.25,
    lack: 0.19,
    polyurtan_matte: 0.125,
    polyurtan_shine: 0.125,
    hardner: 0.350
};
const chalk_cotton = {
    primer: 0.2,
    mesh: 0,
    mid: 1.8,
    base: 0,
    subbase: 0,
    siloxan: 0,
    color: 0.25,
    lack: 0.2,
    polyurtan_matte: 0.125,
    polyurtan_shine: 0.125,
    hardner: 0.350
};

const chalk = {
    chalk_minimal,
    chalk_island,
    chalk_spina,
    chalk_ronic,
    chalk_cotton
};

// Cement Surface
const cement_minimal = {
    primer: 0.2,
    mesh: 1,
    mid: 1.1,
    base: 0,
    subbase: 1.1,
    siloxan: 0,
    color: 0.25,
    lack: 0.17,
    polyurtan_matte: 0.125,
    polyurtan_shine: 0.125,
    hardner: 0.350
};
const cement_island = {
    primer: 0.2,
    mesh: 1,
    mid: 1.7,
    base: 0,
    subbase: 1.1,
    siloxan: 0,
    color: 0.25,
    lack: 0.18,
    polyurtan_matte: 0.125,
    polyurtan_shine: 0.125,
    hardner: 0.350
};
const cement_spina = {
    primer: 0.2,
    mesh: 1,
    mid: 1.4,
    base: 0,
    subbase: 1.1,
    siloxan: 0,
    color: 0.25,
    lack: 0.17,
    polyurtan_matte: 0.125,
    polyurtan_shine: 0.125,
    hardner: 0.350
};
const cement_ronic = {
    primer: 0.2,
    mesh: 1,
    mid: 1.6,
    base: 0,
    subbase: 1.1,
    siloxan: 0,
    color: 0.25,
    lack: 0.19,
    polyurtan_matte: 0.125,
    polyurtan_shine: 0.125,
    hardner: 0.350
};
const cement_cotton = {
    primer: 0.2,
    mesh: 1,
    mid: 1.8,
    base: 0,
    subbase: 1.1,
    siloxan: 0,
    color: 0.25,
    lack: 0.2,
    polyurtan_matte: 0.125,
    polyurtan_shine: 0.125,
    hardner: 0.350
};

const cement = {
    cement_minimal,
    cement_island,
    cement_spina,
    cement_ronic,
    cement_cotton
};

// Tile Surface
const tile_minimal = {
    primer: 0.2,
    mesh: 1,
    mid: 1.1,
    base: 0,
    subbase: 1.1,
    siloxan: 0.12,
    color: 0.25,
    lack: 0.17,
    polyurtan_matte: 0.125,
    polyurtan_shine: 0.125,
    hardner: 0.350
};
const tile_island = {
    primer: 0.2,
    mesh: 1,
    mid: 1.7,
    base: 0,
    subbase: 1.1,
    siloxan: 0.12,
    color: 0.25,
    lack: 0.18,
    polyurtan_matte: 0.125,
    polyurtan_shine: 0.125,
    hardner: 0.350
};
const tile_spina = {
    primer: 0.2,
    mesh: 1,
    mid: 1.4,
    base: 0,
    subbase: 1.1,
    siloxan: 0.12,
    color: 0.25,
    lack: 0.17,
    polyurtan_matte: 0.125,
    polyurtan_shine: 0.125,
    hardner: 0.350
};
const tile_ronic = {
    primer: 0.2,
    mesh: 1,
    mid: 1.6,
    base: 0,
    subbase: 1.1,
    siloxan: 0.12,
    color: 0.25,
    lack: 0.19,
    polyurtan_matte: 0.125,
    polyurtan_shine: 0.125,
    hardner: 0.350
};
const tile_cotton = {
    primer: 0.2,
    mesh: 1,
    mid: 1.8,
    base: 0,
    subbase: 1.1,
    siloxan: 0.12,
    color: 0.25,
    lack: 0.2,
    polyurtan_matte: 0.125,
    polyurtan_shine: 0.125,
    hardner: 0.350
};

const tile = {
    tile_minimal,
    tile_island,
    tile_spina,
    tile_ronic,
    tile_cotton
};

const pricesCustomer = {
    primer: 80000,
    mesh: 32000,
    mid: 165000,
    base: 165000,
    subbase: 165000,
    siloxan: 400000,
    color: 0,
    lack: 215000,
    polyurtan_matte: 650000,
    polyurtan_shine: 670000,
    hardner: 60000
}

const pricesSale = {
    primer: 65000,
    mesh: 32000,
    mid: 130000,
    base: 130000,
    subbase: 130000,
    siloxan: 320000,
    color: 0,
    lack: 170000,
    polyurtan_matte: 520000,
    polyurtan_shine: 650000,
    hardner: 50000
}

const implementCosts = {
    usage: 25000,
    launch: 75000,
    profit: 250000,
    comission: 50000
};

const priceImplementation = {
    base: 270000,
    meshing: 15000,
    polyurtan: 20000,
    siloxan: 20000,
    telorance: 20,
    discount: 20000,
    min_meters: 20,
    max_meters: 200,
    texture_cotton: 150000,
    texture_ronic: 70000,
    texture_isla: 40000,
    texture_spina: 70000
};


const items = {
    pricesCustomer,
    pricesSale,
    priceImplementation,
    implementCosts,
    chalk,
    cement,
    tile
}

// eslint-disable-next-line import/no-anonymous-default-export
export default items